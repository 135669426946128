<template>
	<div id="user-view" >
		<div class="text-center" v-if="loading">
			<v-progress-circular
			:size="50"
			color="primary"
			indeterminate
			></v-progress-circular>
		</div>
		<v-form
			v-else
			ref="formRef"
			target="_self"
			action="/editor/"
			method="post"
			enctype="multipart/form-data"
		>

			<v-row class="ma-0 pa-0">
				<v-col cols="12" sm="12" class="align-self-end">
					<v-card class="mb-5" :title="form.aktion == 'editAdMaterialCheck' ? 'Werbemittel bearbeiten' : 'Werbemittel erstellen'">
						<v-card-title>Format wählen</v-card-title>
						<v-card-text class="mt-1">
							<v-radio-group v-model="form.mainData.format" row class="radio-image" :rules="[validators.required]">
								<v-radio name="format" v-for="item in formats" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
									<template v-slot:label>
										<div class="d-flex flex-column">
											<img class="mb-5" width="400px" :src="item.image" :style="{ filter: form.mainData.format === item.value ? 'none' : 'grayscale(100%)' }" @click="form.mainData.format = item.value">
											<template v-if="!item.disabled">
												<a @click="openPdf(item.pdf.default || item.pdf)" >Detaillierte Informationen</a>
											</template>
										</div>
									</template>
								</v-radio>
							</v-radio-group>
						</v-card-text>
					</v-card>
					<v-card class="">
						<v-card-title v-if="form.aktion == 'editAdMaterialCheck'">Werbemittel bearbeiten</v-card-title>
						<v-card-title v-else>Werbemittel erstellen</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="4">
									<v-text-field
									v-model="form.mainData.titel"
									label="Name des Werbemittels"
									type="text"
									outlined
									dense
									hide-details="auto"
									placeholder="Name des Werbemittels"
									name="companyData[titel]"
									></v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-select
										v-model="form.mainData.kampagnenart"
										label="Kampagnenart"
										name="companyData[kampagnenart]"
										item-text='text'
										item-value='value'
										:items="[{text: 'Standardkampagne', value: 'standard'},{text: 'Reaktivierungskampagne', value: 'reaktivierung'}, {text: 'Nachfasskampagne', value: 'nachfass'}]"
										outlined
                    					dense
                    					hide-details="auto"
									>
									</v-select>
								</v-col>
								<v-col cols="12" md="4">
									<v-select
										v-model="form.mainData.zielgruppe"
										label="Zielgruppe Geschlecht"
										name="companyData[zielgruppe]"
										item-text='text'
										item-value='value'
										:items="[{text: 'Alle Geschlechter', value: ''},{text: 'Weiblich', value: 'weiblich'}, {text: 'Männlich', value: 'maenlich'}]"
										outlined
                    					dense
                    					hide-details="auto"
									>
									</v-select>
								</v-col>
								<v-col cols="12" md="4" v-if="form.mainData.kiTextJson != '' && form.aktion == 'editAdMaterialCheck'">
									<v-checkbox
										label="Texte nicht verändern"
										v-model="form.mainData.keepKiText"
										name="keepKiText"
										:value="1"
										hide-details="auto"
									>
									</v-checkbox>
								</v-col>
								<input v-else type="hidden" name="keepKiText" value="0">
								<v-col cols="12" md="4" v-if="form.mainData.midjourneyObject != '' && form.aktion == 'editAdMaterialCheck'">
									<v-checkbox
										label="Bilder nicht verändern"
										v-model="form.mainData.keepKiPictures"
										name="keepKiPictures"
										:value="1"
										hide-details="auto"
									>
									</v-checkbox>
								</v-col>
								<input v-else type="hidden" name="keepKiText" value="0">
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" sm="12" md="4" class="align-self-end">
					<v-card	class="">
						<v-card-title>Stammdaten des Werbetreibenden:</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.mainData.firma"
										label="Firma"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Firma"
										name="companyData[firma]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="12">
									<v-autocomplete
					                    v-model="form.mainData.branche"
					                    :addressLoading="branchLoading"
					                    :items="branchItems"
					                    item-text="branche"
					                    item-value="branche"
					                    :search-input.sync="branchSearch"
					                    hide-details
					                    label="Branche eingeben"
					                    outlined
					                    dense
					                    :filter="fuzzyComparator"
					                    @blur="updateAddressModel"
					                    name="companyData[branche]"
					                  >
					              	</v-autocomplete>
								</v-col>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.mainData.produkt"
										label="Produkt (Keywords mit Komma getrennt)"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Produkt (Keywords mit Komma getrennt)"
										name="companyData[produkt]"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" sm="12" md="4" class="align-self-end">
					<v-card	class="">
						<v-card-title>Kontaktdaten des Werbetreibenden:</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="12">
									<v-text-field
									v-model="form.mainData.website"
									label="Website (URL)"
									type="text"
									outlined
									dense
									hide-details="auto"
									placeholder="Website (URL)"
									name="companyData[website]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.mainData.telefon"
										label="Telefonnummer"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Telefonnummer"
										name="companyData[telefon]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.mainData.email"
										label="E-Mail-Adresse"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="E-Mail-Adresse"
										name="companyData[email]"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" sm="12" md="4" class="align-self-end">
					<v-card	class="">
						<v-card-title>Ansprechpartner:</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="12">
									<v-text-field
									v-model="form.mainData.ansprechpartnerVorname"
									label="Vorname"
									type="text"
									outlined
									dense
									hide-details="auto"
									placeholder="Vorname"
									name="companyData[ansprechpartnerVorname]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.mainData.ansprechpartnerNachname"
										label="Nachname"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Nachname"
										name="companyData[ansprechpartnerNachname]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.mainData.ansprechpartnerFunktion"
										label="Funktion"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Funktion"
										name="companyData[ansprechpartnerFunktion]"
									>
									</v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" sm="12" class="align-self-end">
					<v-card	class="">
						<v-card-title>Gutschein / Rabatt / Spende einfügen:</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="4">
									<v-select
										v-model="form.mainData.gutscheinArt"
										label="Art auswählen"
										name="companyData[gutscheinArt]"
										item-text='text'
										item-value='value'
										:items="[{text: 'Keine', value: ''},{text: 'Gutschein', value: 'Gutschein'}, {text: 'Rabatt', value: 'Rabatt'}, {text: 'Spendenaktion', value: 'Spendenaktion'}, {text: 'Geschenk', value: 'Geschenk'}]"
										outlined
                    					dense
                    					hide-details="auto"
									>
									</v-select>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt != 'Spendenaktion' && form.mainData.gutscheinArt != ''">
									<v-select
										v-model="form.mainData.gutscheinText"
										label="Gültigkeit"
										name="companyData[gutscheinText]"
										item-text='text'
										item-value='value'
										:items="[{text: 'Unbegrenzt', value: 'Code auf der Rückseite|Code weiter unten'},{text: 'Vor Ort bis:', value: 'Vor Ort bis:'}, {text: 'Gültig bis:', value: 'Gültig bis:'}]"
										outlined
                    					dense
                    					hide-details="auto"
									>
									</v-select>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt != 'Spendenaktion' && form.mainData.gutscheinArt != ''">
									<v-text-field
										v-model="form.mainData.gutscheinDatum"
										label="Gültig bis (Datum)"
										type="date"
										outlined
										dense
										hide-details="auto"
										placeholder="Gültig bis (Datum)"
										name="companyData[gutscheinDatum]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt != 'Spendenaktion' && form.mainData.gutscheinArt != 'Geschenk' && form.mainData.gutscheinArt != ''">
									<v-text-field
										v-model="form.mainData.gutscheinBetrag"
										label="Wert"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Wert"
										name="companyData[gutscheinBetrag]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt != 'Spendenaktion' && form.mainData.gutscheinArt != 'Geschenk' && form.mainData.gutscheinArt != ''">
									<v-select
										v-model="form.mainData.gutscheinWaehrung"
										label="Währung"
										name="companyData[gutscheinWaehrung]"
										:items="['€', '%']"
										outlined
                    					dense
                    					hide-details="auto"
									>
									</v-select>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt == 'Geschenk'">
									<v-text-field
										v-model="form.mainData.geschenkName"
										label="Geschenkname"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Geschenkname"
										name="companyData[geschenkName]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt != 'Spendenaktion' && form.mainData.gutscheinArt != ''">
									<v-text-field
										v-model="form.mainData.gutscheinCode"
										:label="form.mainData.gutscheinArt != 'Geschenk' ? 'Gutschein-Code' : 'Geschenk-Code'"
										type="text"
										outlined
										dense
										hide-details="auto"
										:placeholder="form.mainData.gutscheinArt != 'Geschenk' ? 'Gutschein-Code' : 'Geschenk-Code'"
										name="companyData[gutscheinCode]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="4" v-if="form.mainData.gutscheinArt != '' && form.aktion != 'Spendenaktion'">
									<v-checkbox
										label="Rechtstext Apotheke einfügen"
										v-model="form.mainData.istApotheke"
										name="companyData[istApotheke]"
										:value="1"
										hide-details="auto"
									>
									</v-checkbox>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt == 'Spendenaktion'">
									<v-text-field
										v-model="form.mainData.spendenKonto"
										label="Spendenkonto"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Spendenkonto"
										name="companyData[spendenKonto]"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="4" v-show="form.mainData.gutscheinArt == 'Spendenaktion'">
									<v-text-field
										v-model="form.mainData.spendenUrl"
										label="Spenden-URL"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Spenden-URL"
										name="companyData[spendenUrl]"
									>
									</v-text-field>
								</v-col>

								<input v-if="form.aktion == 'editAdMaterialCheck'" type="hidden" name="aktion" value="editAdMaterial">
								<input v-else type="hidden" name="aktion" value="createAdMaterial">
								<input v-if="form.aktion == 'editAdMaterialCheck'" type="hidden" name="idPtWerbemittel" :value="form.mainData.idPtWerbemittel">

								<input type="hidden" name="reloadToken" :value="form.timestamp">
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="12">
					<v-btn v-if="form.aktion == 'editAdMaterialCheck'" color="accent" type="submit">
						Werbemittel bearbeiten
					</v-btn>
					<v-btn v-else color="accent" type="submit">
						Werbemittel erstellen
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import {onMounted, ref, getCurrentInstance, watch, nextTick} from '@vue/composition-api'
import router from '@/router'
import {
	mdiHomeOutline,
	mdiEmailOutline,
	mdiLockOutline,
	mdiEyeOffOutline,
	mdiEyeOutline
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";
import themeConfig from '@themeConfig'

export default {
	components: {

	},
	setup(props) {

		const formats = ref([
		{
			label: 'Maxipostkarte',
			value: 'maxipostkarte',
			image: require('@/assets/images/nitramit/2024-04-19-Maxipostkarte.png'),
			pdf: require('@/assets/pdfs/maxipostkarte.pdf'),
			width: 241,
			height: 131,
			disabled: false,
		},
		{
			label: 'DINA4-Mailing',
			value: 'dina4mailing',
			image: require('@/assets/images/nitramit/2024-04-19-DINA4-mailing.png'),
			pdf: require('@/assets/pdfs/dina4mailing.pdf'),
			width: 216,
			height: 303,
			disabled: false,
		}
		]);

		const userTab = ref(null)
		const tabs = ref([
			{ icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
		])

		const vm = getCurrentInstance().proxy
		vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
		vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

		const formRef = ref(null)
		const form = ref({})

		const updateAddressModel = () => {
	      // Update the underlying data model with the current value of the input field
	      form.value.mainData.branche = form.value.mainData.branche;
	    };

		form.value = {
			mainData : {
				idPtWerbemittel : router.currentRoute.params.idPtWerbemittel,
				titel : '',
			},
			timestamp: Date.now(),
			aktion: (router.currentRoute.params.idPtWerbemittel !== undefined ? 'editAdMaterialCheck' : 'createAdMaterialCheck'),
		};
		const loading = ref(true)

		var query = router.currentRoute.query;

		if (query.titel != undefined) {
			form.value.mainData.titel = query.titel;
		}
		if (query.firma != undefined) {
			form.value.mainData.firma = query.firma;
		}
		if (query.branche != undefined) {
			form.value.mainData.branche = query.branche;
		}
		if (query.produkt != undefined) {
			form.value.mainData.produkt = query.produkt;
		}
		if (query.website != undefined) {
			form.value.mainData.website = query.website;
		}
		if (query.telefon != undefined) {
			form.value.mainData.telefon = query.telefon;
		}
		if (query.email != undefined) {
			form.value.mainData.email = query.email;
		}
		if (query.ansprechpartnerVorname != undefined) {
			form.value.mainData.ansprechpartnerVorname = query.ansprechpartnerVorname;
		}
		if (query.ansprechpartnerNachname != undefined) {
			form.value.mainData.ansprechpartnerNachname = query.ansprechpartnerNachname;
		}
		if (query.ansprechpartnerFunktion != undefined) {
			form.value.mainData.ansprechpartnerFunktion = query.ansprechpartnerFunktion;
		}
		if (query.gutscheinArt != undefined) {
			form.value.mainData.gutscheinArt = query.gutscheinArt;
		}
		if (query.gutscheinText != undefined) {
			form.value.mainData.gutscheinText = query.gutscheinText;
		}
		if (query.gutscheinDatum != undefined) {
			form.value.mainData.gutscheinDatum = query.gutscheinDatum;
		}
		if (query.gutscheinBetrag != undefined) {
			form.value.mainData.gutscheinBetrag = query.gutscheinBetrag;
		}
		if (query.gutscheinWaehrung != undefined) {
			form.value.mainData.gutscheinWaehrung = query.gutscheinWaehrung;
		}
		if (query.gutscheinCode != undefined) {
			form.value.mainData.gutscheinCode = query.gutscheinCode;
		}
		if (query.spendenKonto != undefined) {
			form.value.mainData.spendenKonto = query.spendenKonto;
		}
		if (query.spendenUrl != undefined) {
			form.value.mainData.spendenUrl = query.spendenUrl;
		}
		if (query.geschenkName != undefined) {
			form.value.mainData.geschenkName = query.geschenkName;
		}

		onMounted(()=>{

			if(form.value.aktion == 'editAdMaterialCheck'){
				axios.post('/api/werbemittel/', {
					aktion: 'editAdMaterial',
					mainData:{
						idPtWerbemittel : router.currentRoute.params.idPtWerbemittel,
					}
				})
				.then((response) => {
					if (response.data.valid === true) {

						if (response.data.adMaterial.midjourneyObject != '') {
							response.data.adMaterial.keepKiPictures = 1;
						}

						if (response.data.adMaterial.kiTextJson != '') {
							response.data.adMaterial.keepKiText = 1;
						}

						form.value.mainData = response.data.adMaterial;

						branchItems.value = [
							{"branche" : form.value.mainData.branche}
						];
						loading.value = false;
					} else {
						response.data.errors.forEach(item=>{
						let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
						vm.$toastr.e(item.errorText, item.errorTitle+suffix);
						})
					}
					loading.value = false;
				})
				.catch((error) => {
					console.log(error)
					loading.value = true;
				})
			}
			else{
				loading.value = false;
			}
		})

		const saveData = () => {
			const isFormValid = formRef.value.validate()
			if(!isFormValid)   {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
			loading.value = true;

			const fd = new FormData();
			fd.append('aktion',form.value.aktion);
			fd.append('mainData[idPtWerbemittel]',form.value.mainData.idPtWerbemittel);
			fd.append('mainData[titel]',form.value.mainData.titel);


			axios
			.post('/api/werbemittel/',fd)
			.then(response => {
				if(response.data.valid === true){
				router.push('/werbemittel',()=>{
					vm.$toastr.s(response.data.message.text, response.data.message.title);
				})
				}
				else{
				response.data.errors.forEach(item=>{
					let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
					vm.$toastr.e(item.errorText, item.errorTitle+suffix);
				})
				}
				loading.value = false;
			})
			.catch(error => {
				vm.$toastr.e(error,error);
				loading.value = false;
			})
		}

		const branchLoading = ref(false)
	    const branchItems = ref([])
	    const branchSearch = ref(null)
	    const branchQuerySelections = query => {
	      branchLoading.value = true

	      let fd = new FormData();
	      fd.append('aktion','fetchBranch');
	      fd.append('mainData[branch]', query);

	      branchItems.value = [];
	      axios
	        .post('/api/ajaxServer/',fd)
	        .then(response => {
	        	console.log('loading')
	          branchItems.value = response.data.hits;
	          branchLoading.value = false
	        })
	    }
	    const debouncedAddressQuerySelections = debounce(branchQuerySelections, 300, true);
	    watch(branchSearch, query => {
	      query && query !== form.value.mainData.branche && debouncedAddressQuerySelections(query)

	    })
	    function debounce(func, delay, trailing) {
	      let timeoutId;
	      return function () {
	        const context = this;
	        const args = arguments;
	        clearTimeout(timeoutId);

	        if (trailing) {
	          timeoutId = setTimeout(() => {
	            func.apply(context, args);
	          }, delay);
	        } else {
	          func.apply(context, args);
	        }
	      };
	    }

	    const fuzzyComparator = (a, searchQuery, c) => {
	      return true;
	    }

	    const openPdf = (pdf) => {
			window.open(pdf, '_blank');
		}

	    return {
			branchLoading,
			branchItems,
			branchSearch,
			updateAddressModel,
			userTab,
			tabs,
			fuzzyComparator,
			form,
			formRef,
			saveData,
			loading,
			formats,
			openPdf,
			icons: {
			mdiHomeOutline,
			mdiEmailOutline,
			mdiLockOutline,
			mdiEyeOffOutline,
			mdiEyeOutline,
			},
			validators: {
			required,
			emailValidator,
			},
		}
	},
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
	display: none;
}
#user-tabs-content{
	margin:0 !important;
}

#chart-sales-overview {
	position: relative;
	top:-30px;
	.apexcharts-canvas {
	.apexcharts-text {
		&.apexcharts-datalabel-value {
		font-weight: 600;
		}
		&.apexcharts-datalabel-label {
		font-size: 1rem;
		}
	}
	}
}

.sales-overview-stats-table {
	width: 100%;
	td {
	padding-bottom: 1rem;
	}

	.stats-dot {
	padding: 0.33rem;
	}

	// Set opacity of dots
	tr {
	&:nth-of-type(1) {
		td:nth-of-type(2) {
		.stats-dot {
			opacity: 0.7;
		}
		}
	}
	&:nth-of-type(2) {
		td:nth-of-type(1) {
		.stats-dot {
			opacity: 0.5;
		}
		}
		td:nth-of-type(2) {
		.stats-dot {
			opacity: 0.15;
		}
		}
	}
	}
}

.v-card__subtitle, .v-card__text #chart-sales-overview{
	font-size: unset;
}

.v-file-input {
	flex-direction: row-reverse;
}
.v-input--selection-controls {
	margin-top: 0px;
	padding-top: 0px;
}

.v-input__append-outer {
	white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
	position: absolute;
}
.gamification-tree {
	top: 10%;
	right: 0;
}
.gamification-john-pose-2 {
	bottom: 0;
	right: 15%;
}
.gamification-tree-4 {
	bottom: 0;
	right: 30%;
}

.refreshIcon {
	position: absolute !important;
	right: 30px;
}

@media (max-width: 600px) {
	.gamification-tree,
	.gamification-tree-4 {
	display: none;
	}
	.gamification-john-pose-2 {
	right: 5%;
	}
}

@media (max-width: 500px) {
	.gamification-john-pose-2 {
	max-width: 70px;
	}
}

@media (max-width: 400px) {
	.greeting-title {
	font-size: 1.2rem !important;
	}
}

// rtl
.v-application {
	&.v-application--is-rtl {
	.gamification-john-pose-2 {
		right: initial;
		left: 15%;
	}
	.gamification-tree {
		right: initial;
		left: 0;
	}
	.gamification-tree-4 {
		left: 30%;
		right: initial;
	}
	}
}
.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
	border-top-left-radius: unset;
	border-top-right-radius: unset;
}

.radio-image .v-input--selection-controls__input{
	display:none;
}
.radio-image .v-input--radio-group__input{
	justify-content: space-around;
}
</style>
